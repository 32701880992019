import type { Ref } from 'vue';
import { computed, ref } from 'vue';

import type {
  BetlineLeagueTopPlayers,
  CoreSportlineFetchOptions,
} from 'web/src/modules/sportline/types/rest';
import { useExpectedIdLoading } from 'web/src/modules/sportline/composables/navigation';
import { getBetlineLeagueTopPlayers } from 'web/src/modules/sportline/utils/api';
import {
  isStatisticNotFoundError,
  isTopPlayersEmpty,
} from 'web/src/modules/sportline/utils/statistic';

interface UseLeagueTopPlayersStoreComposableProps {
  isEnabled: Ref<boolean>;
  expectedId: Ref<Maybe<number>>;
}

interface UseLeagueTopPlayersStoreComposableComposable {
  isLoading: Ref<boolean>;
  isReady: Ref<boolean>;
  isEmpty: Ref<boolean>;
  topPlayers: Ref<Maybe<BetlineLeagueTopPlayers>>;
  fetchLeagueTopPlayers({ silent }: CoreSportlineFetchOptions): Promise<void>;
  clear(): void;
}

export function useLeagueTopPlayersStoreComposable(
  props: UseLeagueTopPlayersStoreComposableProps,
): UseLeagueTopPlayersStoreComposableComposable {
  const { isEnabled, expectedId } = props;

  const {
    isLoading,
    isExpectedIdLoaded,
    setIsLoading,
    setLastLoadedId,
  } = useExpectedIdLoading({ expectedId });

  const topPlayers = ref<Maybe<BetlineLeagueTopPlayers>>(null);
  const isReady = computed<boolean>(() => (!isEnabled.value || isExpectedIdLoaded.value));
  const isEmpty = computed<boolean>(() => (topPlayers.value === null));

  function clear(): void {
    topPlayers.value = null;
  }

  async function fetchLeagueTopPlayers({ silent }: CoreSportlineFetchOptions): Promise<void> {
    if (!isEnabled.value) {
      return;
    }

    const leagueId = expectedId.value;

    if (!leagueId) { return; }

    setIsLoading(true);

    try {
      const result = await getBetlineLeagueTopPlayers({ leagueId }, { silent });
      topPlayers.value = !isTopPlayersEmpty(result) ? { ...result } : null;
    } catch (error: unknown) {
      clear();
      if (!isStatisticNotFoundError(error)) { throw error; }
    } finally {
      setIsLoading(false);
      setLastLoadedId(leagueId);
    }
  }

  return {
    isReady,
    isLoading,
    isEmpty,
    topPlayers,
    fetchLeagueTopPlayers,
    clear,
  };
}
