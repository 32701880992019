import type { Ref } from 'vue';
import { computed, ref } from 'vue';

import type {
  BetlineLeagueMatchesResults,
  BetlineLeagueMatchesStatistic,
  CoreSportlineFetchOptions,
} from 'web/src/modules/sportline/types/rest';
import { useExpectedIdLoading } from 'web/src/modules/sportline/composables/navigation';
import { getBetlineLeagueStatistics } from 'web/src/modules/sportline/utils/api';
import {
  isLeagueMatchesStatisticEmpty,
  isStatisticNotFoundError,
} from 'web/src/modules/sportline/utils/statistic';

interface UseLeagueMatchesStatisticStoreComposableProps {
  isEnabled: Ref<boolean>;
  expectedId: Ref<Maybe<number>>;
}

interface UseLeagueMatchesStatisticStoreComposable {
  isLoading: Ref<boolean>;
  isReady: Ref<boolean>;
  isEmpty: Ref<boolean>;
  leagueMatchesStatistic: Ref<Maybe<BetlineLeagueMatchesStatistic>>;
  leagueMatchesResults: Ref<Maybe<BetlineLeagueMatchesResults>>;
  fetchLeagueMatchesStatistic({ silent }: CoreSportlineFetchOptions): Promise<void>;
  clear(): void;
}

export function useLeagueMatchesStatisticStoreComposable(
  props: UseLeagueMatchesStatisticStoreComposableProps,
): UseLeagueMatchesStatisticStoreComposable {
  const { isEnabled, expectedId } = props;

  const {
    isLoading,
    isExpectedIdLoaded,
    setIsLoading,
    setLastLoadedId,
  } = useExpectedIdLoading({ expectedId });

  const leagueMatchesStatistic = ref<Maybe<BetlineLeagueMatchesStatistic>>(null);
  const leagueMatchesResults = ref<Maybe<BetlineLeagueMatchesResults>>(null);

  const isReady = computed<boolean>(() => (!isEnabled.value || isExpectedIdLoaded.value));
  const isEmpty = computed<boolean>(() => (
    leagueMatchesStatistic.value === null && leagueMatchesResults.value === null
  ));

  function clear(): void {
    leagueMatchesStatistic.value = null;
    leagueMatchesResults.value = null;
  }

  async function fetchLeagueMatchesStatistic({ silent }: CoreSportlineFetchOptions): Promise<void> {
    if (!isEnabled.value) {
      return;
    }

    const leagueId = expectedId.value;

    if (!leagueId) { return; }

    setIsLoading(true);

    try {
      const response = await getBetlineLeagueStatistics({ leagueId }, { silent });
      const {
        average,
        sum,
        overallGoals,
        overallResults,
      } = response || {};
      const statistic = { average, sum };
      const results = { overallGoals, overallResults };

      leagueMatchesStatistic.value = !isLeagueMatchesStatisticEmpty(statistic) ? statistic : null;
      leagueMatchesResults.value = !isLeagueMatchesStatisticEmpty(results) ? results : null;
    } catch (error: unknown) {
      clear();
      if (!isStatisticNotFoundError(error)) { throw error; }
    } finally {
      setIsLoading(false);
      setLastLoadedId(leagueId);
    }
  }

  return {
    isLoading,
    isReady,
    isEmpty,
    leagueMatchesStatistic,
    leagueMatchesResults,
    fetchLeagueMatchesStatistic,
    clear,
  };
}
