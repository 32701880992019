import type { Ref } from 'vue';
import { computed, ref, watch } from 'vue';

import { LeaguePageSectionId } from 'web/src/modules/sportline/submodules/league/enums';

interface UseLeaguePageActiveSectionProps {
  canLoadStatistics: Ref<boolean>;
  canDisplayExpectedEventsList: Ref<boolean>;
  canDisplayExpectedStatistic: Ref<boolean>;
  canDisplayExpectedPromotions: Ref<boolean>;
  canDisplayPreviousEventsList: Ref<boolean>;
  canDisplayPreviousStatistic: Ref<boolean>;
  canDisplayPreviousPromotions: Ref<boolean>;
}

interface UseLeaguePageActiveSectionEmits {
  onSectionActivated({ id }: { id: Maybe<LeaguePageSectionId> }): void;
}

interface UseLeaguePageActiveSectionComposable {
  activeSectionId: Ref<LeaguePageSectionId>;
  isEventsTabAvailable: Ref<boolean>;
  isStatisticTabAvailable: Ref<boolean>;
  isPromotionsTabAvailable: Ref<boolean>;
  selectSection({ id }: { id: LeaguePageSectionId }): void;
}

export function useLeaguePageActiveSection(
  props: UseLeaguePageActiveSectionProps,
  emit: UseLeaguePageActiveSectionEmits,
): UseLeaguePageActiveSectionComposable {
  const {
    canLoadStatistics,
    canDisplayExpectedEventsList,
    canDisplayExpectedStatistic,
    canDisplayExpectedPromotions,
    canDisplayPreviousEventsList,
    canDisplayPreviousStatistic,
    canDisplayPreviousPromotions,
  } = props;

  const selectedSectionId = ref<Maybe<LeaguePageSectionId>>(null);

  const isEventsTabAvailable = computed<boolean>(() => (
    canDisplayExpectedEventsList.value || canDisplayPreviousEventsList.value
  ));
  const isStatisticTabAvailable = computed(() => (
    canLoadStatistics.value || canDisplayExpectedStatistic.value || canDisplayPreviousStatistic.value
  ));
  const isPromotionsTabAvailable = computed(() => (
    canDisplayExpectedPromotions.value || canDisplayPreviousPromotions.value
  ));

  const availableSection = computed<LeaguePageSectionId[]>(() => {
    const result: LeaguePageSectionId[] = [];
    if (isEventsTabAvailable.value) { result.push(LeaguePageSectionId.EventsList); }
    if (isStatisticTabAvailable.value) { result.push(LeaguePageSectionId.Statistic); }
    if (isPromotionsTabAvailable.value) { result.push(LeaguePageSectionId.Promotions); }
    return result;
  });
  const activeSectionId = computed<LeaguePageSectionId>(() => {
    if (selectedSectionId.value && availableSection.value.includes(selectedSectionId.value)) {
      return selectedSectionId.value;
    }

    return availableSection.value[0] || LeaguePageSectionId.None;
  });

  function selectSection({ id }: { id: Maybe<LeaguePageSectionId> }): void {
    selectedSectionId.value = id;
  }

  watch(activeSectionId, (id) => {
    emit.onSectionActivated({ id });
  }, { immediate: true });

  return {
    activeSectionId,
    isEventsTabAvailable,
    isStatisticTabAvailable,
    isPromotionsTabAvailable,
    selectSection,
  };
}
