import type { Ref } from 'vue';
import { computed, ref } from 'vue';

import type {
  BetlineLeagueStanding,
  CoreSportlineFetchOptions,
} from 'web/src/modules/sportline/types/rest';
import { useExpectedIdLoading } from 'web/src/modules/sportline/composables/navigation';
import { getBetlineLeagueStandings } from 'web/src/modules/sportline/utils/api';
import { isStatisticNotFoundError } from 'web/src/modules/sportline/utils/statistic';

interface UseLeagueStandingsStoreComposableProps {
  isEnabled: Ref<boolean>;
  expectedId: Ref<Maybe<number>>;
}

interface UseLeagueStandingsStoreComposable {
  isLoading: Ref<boolean>;
  isReady: Ref<boolean>;
  isEmpty: Ref<boolean>;
  leagueStandings: Ref<Maybe<BetlineLeagueStanding[]>>;
  fetchLeagueStandings({ silent }: CoreSportlineFetchOptions): Promise<void>;
  clear(): void;
}

export function useLeagueStandingsStoreComposable(
  props: UseLeagueStandingsStoreComposableProps,
): UseLeagueStandingsStoreComposable {
  const { isEnabled, expectedId } = props;

  const {
    isLoading,
    isExpectedIdLoaded,
    setIsLoading,
    setLastLoadedId,
  } = useExpectedIdLoading({ expectedId });

  const leagueStandings = ref<Maybe<BetlineLeagueStanding[]>>(null);

  const isReady = computed<boolean>(() => (!isEnabled.value || isExpectedIdLoaded.value));
  const isEmpty = computed<boolean>(() => ((leagueStandings.value?.length || 0) === 0));

  function clear(): void {
    leagueStandings.value = null;
  }

  async function fetchLeagueStandings({ silent }: CoreSportlineFetchOptions): Promise<void> {
    if (!isEnabled.value) {
      return;
    }

    const leagueId = expectedId.value;

    if (!leagueId) { return; }

    setIsLoading(true);

    try {
      const result = await getBetlineLeagueStandings({ leagueId }, { silent });
      leagueStandings.value = result?.standings ? [...result.standings] : null;
    } catch (error: unknown) {
      clear();
      if (!isStatisticNotFoundError(error)) { throw error; }
    } finally {
      setIsLoading(false);
      setLastLoadedId(leagueId);
    }
  }

  return {
    isLoading,
    isReady,
    isEmpty,
    leagueStandings,
    fetchLeagueStandings,
    clear,
  };
}
