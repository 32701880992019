import type { Ref } from 'vue';
import { computed, ref } from 'vue';

import { SportFilterKickoffPeriod } from '@leon-hub/api-sdk';

import type { ConfigForLeagueLoading } from 'web/src/modules/sportline/submodules/league/types';
import type { SportDateTimeFilter } from 'web/src/modules/sportline/submodules/navigation/store/types';
import type {
  LeagueElement,
  OutrightSportlineEvent,
  RegionElement,
  SportElement,
  SportlineEvent,
} from 'web/src/modules/sportline/types';
import type {
  CoreSportlineFetchOptions,
  GetSportEventsResponse,
} from 'web/src/modules/sportline/types/rest';
import { useBroadcastSelected } from 'web/src/modules/sportline/composables/broadcast';
import { useExpectedIdLoading } from 'web/src/modules/sportline/composables/navigation';
import { useParseSportlineSettingsRef } from 'web/src/modules/sportline/composables/settings';
import { SportlineType } from 'web/src/modules/sportline/enums';
import { useSportlineApiService } from 'web/src/modules/sportline/services';
import { useSyncSportline } from 'web/src/modules/sportline/submodules/sync-sportline';
import { useLeagueEventsSportlineUpdateTimeout } from 'web/src/modules/sportline/submodules/update-timeout';
import {
  filterSportsListEvents,
  isEmptyFilter,
  splitSportElementLeaguesByDateFilter,
} from 'web/src/modules/sportline/utils';
import { normalizeEventsChangesResponseToDefaultResponse } from 'web/src/modules/sportline/utils/response';
import { SportlineFactory } from 'web/src/modules/sportline/utils/rest';
import { BackgroundUpdateStopwatch } from 'web/src/utils/store';
import useBackgroundRequestsLifeCycle from 'web/src/utils/store/composables/useBackgroundRequestsLifeCycle';

interface UseLeagueEventsStoreComposableProps {
  expectedId: Ref<Maybe<number>>;
  configForLeagueLoading: Ref<ConfigForLeagueLoading>;
}

interface UseLeagueEventsStoreComposable {
  isReady: Ref<boolean>;
  isEmpty: Ref<boolean>;
  isLoading: Ref<boolean>;
  rawEventsResponse: Ref<Maybe<GetSportEventsResponse> | false>;
  sportElement: Ref<Maybe<SportElement>>;
  regionElement: Ref<Maybe<RegionElement>>;
  leagueElement: Ref<Maybe<LeagueElement>>;
  outrightEvents: Ref<SportElement<OutrightSportlineEvent>[]>;
  hasEvents: Ref<boolean>;
  hasOutrightEvents: Ref<boolean>;
  clearEventsResponse(): void;
  onInit(): void;
  initialRequests(): Promise<void>;
  syncBackgroundRequests(options: { force?: boolean; silent?: boolean }): Promise<void>;
  setBackgroundUpdateEnabled(value: boolean): Promise<void>;
}

export function useLeagueEventsStoreComposable(
  props: UseLeagueEventsStoreComposableProps,
): UseLeagueEventsStoreComposable {
  const { expectedId, configForLeagueLoading } = props;

  const apiService = useSportlineApiService();
  const broadcastSelected = useBroadcastSelected();

  const { timeout: leagueEventsUpdateInterval } = useLeagueEventsSportlineUpdateTimeout();
  const parseSportlineSettings = useParseSportlineSettingsRef();

  const {
    isLoading,
    lastLoadedId,
    isExpectedIdLoaded,
    setIsLoading,
    setLastLoadedId,
  } = useExpectedIdLoading({ expectedId });

  const rawEventsResponse = ref<Maybe<GetSportEventsResponse> | false>(null);

  const liveFilter: SportDateTimeFilter = {
    activeKickoffPeriod: SportFilterKickoffPeriod.ALL,
    sportlineType: SportlineType.Live,
  };

  const isReady = computed<boolean>(() => (rawEventsResponse.value !== null && isExpectedIdLoaded.value));
  const sportElement = computed<Maybe<SportElement>>(() => {
    if (!rawEventsResponse.value) {
      return null;
    }
    const { filter } = configForLeagueLoading.value;

    return splitSportElementLeaguesByDateFilter(
      (new SportlineFactory<SportlineEvent>(rawEventsResponse.value, parseSportlineSettings.value)
        .build())[0] || null,
      !isEmptyFilter(filter) ? [liveFilter, { ...filter }] : [liveFilter],
    );
  });
  const regionElement = computed<Maybe<RegionElement>>(
    () => sportElement.value?.regions[0] ?? null,
  );
  const leagueElement = computed<Maybe<LeagueElement>>(
    () => regionElement.value?.leagues[0] ?? null,
  );
  const hasEvents = computed<boolean>(
    () => (leagueElement.value?.sportEvents?.length || 0) > 0,
  );
  const outrightEvents = computed<SportElement<OutrightSportlineEvent>[]>(
    () => {
      // @TODO add type guard
      const sportElementValue = sportElement.value as Maybe<SportElement<OutrightSportlineEvent>>;
      return filterSportsListEvents<OutrightSportlineEvent>(
        (sportElementValue ? [sportElementValue] : []),
        (item) => item.sportEvent.isOutright,
      );
    },
  );
  const hasOutrightEvents = computed(() => outrightEvents.value.length > 0);
  const isEmpty = computed(() => !hasEvents.value && !hasOutrightEvents.value);

  const lastUpdate = new BackgroundUpdateStopwatch<['leagueEvents']>({
    leagueEvents: async ({ silent }: CoreSportlineFetchOptions) => {
      const leagueId = expectedId.value;
      const isUpdateRequest = leagueId === lastLoadedId.value;

      if (!leagueId) {
        return;
      }

      setIsLoading(true);

      try {
        const response = await apiService.loadLeagueEvents({
          leagueId: String(leagueId),
          // here could be "false" value in rawEventsResponse, nullish-coalescing is not working here
          // eslint-disable-next-line ts/prefer-nullish-coalescing
          vTag: isUpdateRequest ? (rawEventsResponse.value || null)?.vtag : undefined,
          silent,
        });

        // user went away from the page
        if (leagueId !== expectedId.value) {
          return;
        }

        rawEventsResponse.value = normalizeEventsChangesResponseToDefaultResponse(response);
        broadcastSelected.updateDataInStorageByResponse({ response });
        lastUpdate.update('leagueEvents');
      } catch (error: unknown) {
        // set error value for first load
        rawEventsResponse.value = false;
        throw error;
      } finally {
        setIsLoading(false);
        setLastLoadedId(leagueId);
      }
    },
  });
  const {
    initialRequests,
    syncState,
    syncBackgroundRequests,
    setBackgroundUpdateEnabled,
  } = useBackgroundRequestsLifeCycle({
    lastUpdate,
    updateInterval: leagueEventsUpdateInterval,
  });

  function onInit(): void {
    useSyncSportline(async (silent: boolean): Promise<void> => {
      await syncState({ silent });
    }, leagueEventsUpdateInterval);
  }

  function clearEventsResponse(): void {
    rawEventsResponse.value = null;
    lastLoadedId.value = null;
  }

  return {
    isReady,
    isEmpty,
    isLoading,
    rawEventsResponse,
    sportElement,
    regionElement,
    leagueElement,
    outrightEvents,
    hasEvents,
    hasOutrightEvents,
    clearEventsResponse,
    onInit,
    initialRequests,
    syncBackgroundRequests,
    setBackgroundUpdateEnabled,
  };
}
