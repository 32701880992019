import type { BaseRouteNameType } from '@leon-hub/routing-config-names';
import { isString } from '@leon-hub/guards';
import { RouteName } from '@leon-hub/routing-config-names';

export function isLeaguePageRouteName(value?: unknown): value is BaseRouteNameType {
  return isString(value) && (
    value === RouteName.SPORT_LEAGUE as string
    || value === RouteName.SPORTLINE_TOP_LEAGUE as string
    || value === RouteName.SPORTLINE_LIVE_LEAGUE as string
  );
}
