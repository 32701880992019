import type { Ref } from 'vue';
import { computed, ref, watch } from 'vue';

interface UseLeagueReadyStateStoreComposableProps {
  expectedId: Ref<Maybe<number>>;
  loadedDataLeagueId: Ref<Maybe<number>>;
  isLeagueDataReady: Ref<boolean>;
  isLeagueDataEmpty: Ref<boolean>;
  isEventsListReady: Ref<boolean>;
  isEventsListEmpty: Ref<boolean>;
  isStatisticReady: Ref<boolean>;
  isStatisticEmpty: Ref<boolean>;
  isPromotionsReady: Ref<boolean>;
  isPromotionsEmpty: Ref<boolean>;
}

interface UseLeagueReadyStateStoreComposable {
  canDisplayPreviousData: Ref<boolean>;
  canDisplayExpectedData: Ref<boolean>;
  canDisplayAnything: Ref<boolean>;
  canDisplayExpectedLeagueData: Ref<boolean>;
  canDisplayExpectedEventsList: Ref<boolean>;
  canDisplayExpectedStatistic: Ref<boolean>;
  canDisplayExpectedPromotions: Ref<boolean>;
  canDisplayPreviousLeagueData: Ref<boolean>;
  canDisplayPreviousEventsList: Ref<boolean>;
  canDisplayPreviousStatistic: Ref<boolean>;
  canDisplayPreviousPromotions: Ref<boolean>;
}

function useReadyStateComposable(props: {
  isDisplayPreviousDataAvailable: Ref<boolean>;
  isReady: Ref<boolean>;
  isEmpty: Ref<boolean>;
}): {
    canDisplayPreviousData: Ref<boolean>;
    canDisplayExpectedData: Ref<boolean>;
  } {
  const { isDisplayPreviousDataAvailable, isReady, isEmpty } = props;

  const isPreviousDataExist = computed(() => !isReady.value && !isEmpty.value);
  const canDisplayPreviousData = computed(() => (isDisplayPreviousDataAvailable.value && isPreviousDataExist.value));
  const canDisplayExpectedData = computed(() => isReady.value && !isEmpty.value);

  return {
    canDisplayPreviousData,
    canDisplayExpectedData,
  };
}

export function useLeagueReadyStateStoreComposable(
  props: UseLeagueReadyStateStoreComposableProps,
): UseLeagueReadyStateStoreComposable {
  const {
    expectedId,
    loadedDataLeagueId,
    isLeagueDataReady,
    isLeagueDataEmpty,
    isEventsListReady,
    isEventsListEmpty,
    isStatisticReady,
    isStatisticEmpty,
    isPromotionsReady,
    isPromotionsEmpty,
  } = props;

  const isDisplayPreviousDataAvailable = ref(false);

  const {
    canDisplayPreviousData: canDisplayPreviousLeagueData,
    canDisplayExpectedData: canDisplayExpectedLeagueData,
  } = useReadyStateComposable({
    isDisplayPreviousDataAvailable,
    isReady: isLeagueDataReady,
    isEmpty: isLeagueDataEmpty,
  });
  const {
    canDisplayPreviousData: canDisplayPreviousEventsList,
    canDisplayExpectedData: canDisplayExpectedEventsList,
  } = useReadyStateComposable({
    isDisplayPreviousDataAvailable,
    isReady: isEventsListReady,
    isEmpty: isEventsListEmpty,
  });
  const {
    canDisplayPreviousData: canDisplayPreviousStatistic,
    canDisplayExpectedData: canDisplayExpectedStatistic,
  } = useReadyStateComposable({
    isDisplayPreviousDataAvailable,
    isReady: isStatisticReady,
    isEmpty: isStatisticEmpty,
  });
  const {
    canDisplayPreviousData: canDisplayPreviousPromotions,
    canDisplayExpectedData: canDisplayExpectedPromotions,
  } = useReadyStateComposable({
    isDisplayPreviousDataAvailable,
    isReady: isPromotionsReady,
    isEmpty: isPromotionsEmpty,
  });

  const canDisplayPreviousData = computed(() => (
    canDisplayPreviousLeagueData.value
    || canDisplayPreviousEventsList.value
    || canDisplayPreviousStatistic.value
    || canDisplayPreviousPromotions.value
  ));
  const canDisplayExpectedData = computed(() => (
    canDisplayExpectedLeagueData.value
    || canDisplayExpectedEventsList.value
    || canDisplayExpectedStatistic.value
    || canDisplayExpectedPromotions.value
  ));
  const canDisplayAnything = computed(() => (canDisplayPreviousData.value || canDisplayExpectedData.value));

  if (process.env.VUE_APP_LAYOUT_DESKTOP) {
    watch([expectedId, loadedDataLeagueId], ([id, loadedId], [prevExpectedId]) => {
      // display league data only if we navigate between league pages
      isDisplayPreviousDataAvailable.value = !!prevExpectedId && id !== loadedId;
    });
  }

  return {
    canDisplayPreviousData,
    canDisplayExpectedData,
    canDisplayAnything,
    canDisplayExpectedLeagueData,
    canDisplayExpectedEventsList,
    canDisplayExpectedStatistic,
    canDisplayExpectedPromotions,
    canDisplayPreviousLeagueData,
    canDisplayPreviousEventsList,
    canDisplayPreviousStatistic,
    canDisplayPreviousPromotions,
  };
}
