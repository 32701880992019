import type { Ref } from 'vue';
import { computed, ref } from 'vue';

import type {
  BetlineLeaguePlayoff,
  CoreSportlineFetchOptions,
} from 'web/src/modules/sportline/types/rest';
import { useExpectedIdLoading } from 'web/src/modules/sportline/composables/navigation';
import { getBetlineLeaguePlayoff } from 'web/src/modules/sportline/utils/api';
import { isStatisticNotFoundError } from 'web/src/modules/sportline/utils/statistic';

interface UseLeaguePlayoffStoreComposableProps {
  isEnabled: Ref<boolean>;
  expectedId: Ref<Maybe<number>>;
}

interface UseLeaguePlayoffStoreComposable {
  isLoading: Ref<boolean>;
  isReady: Ref<boolean>;
  isEmpty: Ref<boolean>;
  leaguePlayoff: Ref<Maybe<BetlineLeaguePlayoff>>;
  fetchLeagueLayoff({ silent }: CoreSportlineFetchOptions): Promise<void>;
  clear(): void;
}

export function useLeaguePlayoffStoreComposable(
  props: UseLeaguePlayoffStoreComposableProps,
): UseLeaguePlayoffStoreComposable {
  const { isEnabled, expectedId } = props;

  const {
    isLoading,
    isExpectedIdLoaded,
    setIsLoading,
    setLastLoadedId,
  } = useExpectedIdLoading({ expectedId });

  const leaguePlayoff = ref<Maybe<BetlineLeaguePlayoff>>(null);

  const isReady = computed<boolean>(() => (!isEnabled.value || isExpectedIdLoaded.value));
  const isEmpty = computed<boolean>(() => (leaguePlayoff.value === null));

  function clear(): void {
    leaguePlayoff.value = null;
  }

  async function fetchLeagueLayoff({ silent }: CoreSportlineFetchOptions): Promise<void> {
    if (!isEnabled.value) {
      return;
    }

    const leagueId = expectedId.value;

    if (!leagueId) { return; }

    setIsLoading(true);

    try {
      leaguePlayoff.value = await getBetlineLeaguePlayoff({ leagueId }, { silent });
    } catch (error: unknown) {
      clear();
      if (!isStatisticNotFoundError(error)) { throw error; }
    } finally {
      setIsLoading(false);
      setLastLoadedId(leagueId);
    }
  }

  return {
    isReady,
    isLoading,
    isEmpty,
    leaguePlayoff,
    fetchLeagueLayoff,
    clear,
  };
}
