import { defineStore } from 'pinia';
import {
  computed,
  ref,
  toRef,
} from 'vue';

import { safeParseInt } from '@leon-hub/utils';

import type { ConfigForLeagueLoading } from 'web/src/modules/sportline/submodules/league/types';
import type { LeagueRouteLocationParameters, SportDateTimeFilter } from 'web/src/modules/sportline/submodules/navigation/store/types';
import type {
  GetSportEventsResponse,
} from 'web/src/modules/sportline/types/rest';
import { useGlobalSportlineEvents } from 'web/src/modules/sportline/composables/core';
import {
  useSportlineEventsChangesListeners,
  useSportlinePageIdentifier,
} from 'web/src/modules/sportline/store/composables';
import useSportlineSettingsStore from 'web/src/modules/sportline/store/useSportlineSettingsStore';
import useCustomerFavoritesService
  from 'web/src/modules/sportline/submodules/favorites/composables/useCustomerFavoritesService';
import { CustomerFavoritesIdsBackgroundUpdateKey } from 'web/src/modules/sportline/submodules/favorites/enums';
import { LeaguePageSectionId } from 'web/src/modules/sportline/submodules/league/enums';
import useAwaitConditionQueue from 'web/src/utils/store/composables/useAwaitConditionQueue';

import {
  useLeagueEventsStoreComposable,
  useLeagueHeaderDataStoreComposable,
  useLeaguePageActiveSection,
  useLeagueReadyStateStoreComposable,
  useLeagueStatisticStoreComposable,
} from './composables';

const useLeaguePageStore = defineStore('sportline-league-page-store', () => {
  const sportlineSettingsStore = useSportlineSettingsStore();
  const customerFavoritesService = useCustomerFavoritesService();

  const {
    pageIdentifier,
    setPageIdentifier,
  } = useSportlinePageIdentifier();

  const isMarketTypesSelectionEnabled = toRef(sportlineSettingsStore, 'isMarketTypesSelectionEnabled');
  const isLeagueStatisticEnabled = toRef(sportlineSettingsStore, 'isLeagueStatisticEnabled');

  const configForLeagueLoading = ref<ConfigForLeagueLoading>({});

  const expectedId = computed<Maybe<number>>(() => {
    const id = pageIdentifier.value.leagueId;
    return id ? safeParseInt(id) : null;
  });
  const expectedStatisticId = computed<Maybe<number>>(() => {
    const id = pageIdentifier.value.statisticId || pageIdentifier.value.leagueId;
    return id ? safeParseInt(id) : null;
  });
  const dateTimeFilter = computed<Maybe<SportDateTimeFilter>>(() => configForLeagueLoading.value.filter ?? null);

  // It will be realised in the future
  const isPromotionsReady = ref(true);
  const isPromotionsEmpty = ref(true);
  const {
    isReady: isEventsListReady,
    isLoading: isEventsListLoading,
    isEmpty: isEventsListEmpty,
    rawEventsResponse,
    sportElement,
    regionElement,
    leagueElement,
    outrightEvents,
    hasEvents,
    hasOutrightEvents,
    clearEventsResponse,
    onInit: onLeagueEventsInit,
    initialRequests: initialLeagueEventsRequests,
    syncBackgroundRequests: syncLeagueEventsBackgroundRequests,
    setBackgroundUpdateEnabled: setLeagueEventsBackgroundUpdateEnabled,
  } = useLeagueEventsStoreComposable({ configForLeagueLoading, expectedId });
  const {
    lastLoadedId: loadedDataLeagueId,
    sportElement: rawHeaderDataSportElement,
    regionElement: rawHeaderDataRegionElement,
    leagueElement: rawHeaderDataLeagueElement,
    leagueData,
    leagueRepresentationData,
    canLoadStatistics,
    isReady: isLeagueDataReady,
    isLoading: isLeagueDataLoading,
    isEmpty: isLeagueDataEmpty,
    onInit: onLeagueDataInit,
    initialRequests: initialLeagueDataRequests,
    syncBackgroundRequests: syncLeagueDataBackgroundRequests,
    setBackgroundUpdateEnabled: setLeagueDataBackgroundUpdateEnabled,
  } = useLeagueHeaderDataStoreComposable({ expectedId: expectedStatisticId });
  const {
    isReady: isStatisticReady,
    isLoading: isStatisticLoading,
    isEmpty: isStatisticEmpty,
    leagueStandings,
    leagueMatchesStatistic,
    leagueMatchesResults,
    leaguePlayoff,
    leagueTopPlayers,
    onInit: onLeagueStatisticInit,
    initialRequests: initialStatisticRequests,
    syncBackgroundRequests: syncLeagueStatisticBackgroundRequests,
    setBackgroundUpdateEnabled: setLeagueStatisticBackgroundUpdateEnabled,
    clear: clearStatistic,
  } = useLeagueStatisticStoreComposable({ expectedId: expectedStatisticId });

  const headerDataSportElement = computed(() => rawHeaderDataSportElement.value ?? sportElement.value);
  const headerDataRegionElement = computed(() => rawHeaderDataRegionElement.value ?? regionElement.value);
  const headerDataLeagueElement = computed(() => rawHeaderDataLeagueElement.value ?? leagueElement.value);

  const isReady = computed(() => (
    process.env.VUE_APP_PRERENDER
      ? isEventsListReady.value && isLeagueDataReady.value && isStatisticReady.value
      : isEventsListReady.value && isLeagueDataReady.value
  ));
  const leagueNavigationParameters = computed<Maybe<LeagueRouteLocationParameters>>(() => (
    headerDataLeagueElement.value?.league.navigationParameters ?? null
  ));

  const leagueReadyStates = useLeagueReadyStateStoreComposable({
    expectedId,
    loadedDataLeagueId,
    isLeagueDataReady,
    isLeagueDataEmpty,
    isEventsListReady,
    isEventsListEmpty,
    isStatisticReady,
    isStatisticEmpty,
    isPromotionsReady,
    isPromotionsEmpty,
  });
  const leaguePageActiveSection = useLeaguePageActiveSection({
    canLoadStatistics,
    ...leagueReadyStates,
  }, {
    onSectionActivated({ id }: { id: Maybe<LeaguePageSectionId> }): void {
      // @TODO there will be requests only for active section (betline, statistic or promotions)

      // turn on statistics updates only with active statistic tab
      if (id === LeaguePageSectionId.Statistic) {
        void setLeagueStatisticBackgroundUpdateEnabled(isLeagueStatisticEnabled.value);
      } else {
        void setLeagueStatisticBackgroundUpdateEnabled(false);
      }
    },
  });
  const { activeSectionId } = leaguePageActiveSection;

  const {
    awaitCondition: awaitStateIsLoaded,
  } = useAwaitConditionQueue({
    source: 'Sportline league page is ready',
    value: isReady,
    condition: (value: boolean) => value,
  });
  const {
    awaitCondition: awaitLeavePage,
  } = useAwaitConditionQueue({
    source: 'Leave sportline league page',
    value: expectedId,
    condition: (value, oldValue) => !value || value !== oldValue,
  });

  const {
    onInit: onGlobalSportlineEventsInit,
  } = useGlobalSportlineEvents();
  const {
    initialRequests: initialFavoritesRequests,
    setIsBackgroundIdsUpdateAvailable: setFavoritesBackgroundUpdateEnabled,
  } = customerFavoritesService;
  const {
    onInit: onSportlineEventsChangesListenersInit,
  } = useSportlineEventsChangesListeners({
    setResponse(response: GetSportEventsResponse): void {
      rawEventsResponse.value = response;
    },
    getResponse(): Maybe<GetSportEventsResponse> {
      // eslint-disable-next-line ts/prefer-nullish-coalescing
      return rawEventsResponse.value || null;
    },
  });

  async function initialRequests(): Promise<void> {
    void initialFavoritesRequests();
    await Promise.all([
      initialLeagueEventsRequests(),
      initialLeagueDataRequests(),
      (process.env.VUE_APP_PRERENDER
        ? initialStatisticRequests()
        : (new Promise<void>((resolve) => {
            if (leaguePageActiveSection.activeSectionId.value !== LeaguePageSectionId.Statistic) {
              clearStatistic();
            }

            resolve();
          }))),
    ]);
  }

  function setBackgroundUpdateEnabled(value: boolean): void {
    void setLeagueEventsBackgroundUpdateEnabled(value);
    void setLeagueDataBackgroundUpdateEnabled(value);
    void setFavoritesBackgroundUpdateEnabled(CustomerFavoritesIdsBackgroundUpdateKey.LEAGUE, value);

    if (!value) {
      // We will only turn off league statistic by this way
      void setLeagueStatisticBackgroundUpdateEnabled(value);
    }
  }

  function syncBackgroundRequests(options: { force?: boolean; silent?: boolean }): void {
    void syncLeagueEventsBackgroundRequests(options);
    void syncLeagueDataBackgroundRequests(options);

    if (activeSectionId.value === LeaguePageSectionId.Statistic) {
      void syncLeagueStatisticBackgroundRequests(options);
    }
  }

  // init store
  onGlobalSportlineEventsInit();
  onSportlineEventsChangesListenersInit();
  onLeagueEventsInit();
  onLeagueDataInit();
  onLeagueStatisticInit();

  return {
    ...leagueReadyStates,

    isReady,
    isLeagueDataReady,
    isLeagueDataEmpty,
    isEventsListReady,
    isEventsListEmpty,
    isStatisticReady,
    isStatisticEmpty,
    isPromotionsReady,
    isPromotionsEmpty,

    isEventsListLoading,
    isStatisticLoading,
    isLeagueDataLoading,

    loadedDataLeagueId,
    leagueNavigationParameters,
    isMarketTypesSelectionEnabled,
    isLeagueStatisticEnabled,
    rawEventsResponse,
    leagueStandings,
    leagueMatchesStatistic,
    leagueMatchesResults,
    leaguePlayoff,
    leagueTopPlayers,
    pageIdentifier,
    dateTimeFilter,
    configForLeagueLoading,
    leagueData,
    leagueRepresentationData,

    headerDataSportElement,
    headerDataRegionElement,
    headerDataLeagueElement,

    sportElement,
    regionElement,
    leagueElement,
    outrightEvents,
    hasEvents,
    hasOutrightEvents,

    initialRequests,
    syncBackgroundRequests,
    setBackgroundUpdateEnabled,
    clearEventsResponse,
    setPageIdentifier,
    awaitStateIsLoaded,
    awaitLeavePage,
    ...leaguePageActiveSection,
    setConfigForLeagueLoading(config: ConfigForLeagueLoading): void {
      configForLeagueLoading.value = config;
    },
    updateDateFilterForLeagueLoading(filter?: SportDateTimeFilter): void {
      configForLeagueLoading.value = { ...configForLeagueLoading.value, filter };
    },
  };
});

export default useLeaguePageStore;
